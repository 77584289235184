var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("text"),
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { disabled: _vm.invalidInvitation },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c("v-text-field", {
            attrs: {
              required: "",
              rules: _vm.emailRules,
              outlined: "",
              dense: "",
              placeholder: "Your e-mail address",
            },
            model: {
              value: _vm.email,
              callback: function ($$v) {
                _vm.email = $$v
              },
              expression: "email",
            },
          }),
          _c("v-text-field", {
            attrs: {
              required: "",
              dense: "",
              outlined: "",
              rules: _vm.emailRules,
              placeholder: "Confirm your e-mail",
            },
            model: {
              value: _vm.emailValidate,
              callback: function ($$v) {
                _vm.emailValidate = $$v
              },
              expression: "emailValidate",
            },
          }),
          _c("div", {
            staticClass: "d-flex justify-center mb-5",
            attrs: { id: "g-recaptcha" },
          }),
          _c(
            "v-btn",
            {
              staticClass: "primary",
              attrs: {
                elevation: "0",
                block: "",
                loading: _vm.loading || _vm.validating,
                disabled: !_vm.valid || _vm.recaptchaError,
              },
              on: {
                click: function ($event) {
                  return _vm.validateEmail()
                },
              },
            },
            [_vm._v(" Request sign up e-mail ")]
          ),
        ],
        1
      ),
      _c("invitation-errors-dialog", {
        attrs: {
          showDialog: _vm.showInvitationErrorsDialog,
          errorType: _vm.validationErrorType,
          invalidEmails: _vm.invalidEmailsList,
          isSignUpDialog: true,
        },
        on: {
          proceedWithTheInvitation: function ($event) {
            return _vm.trySignUp($event.value)
          },
          validateEmailsAgain: function ($event) {
            return _vm.validateEmail($event.value)
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }